import * as React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet";

import Layout from "../components/Layout"
import Banner from "../components/Banner"
import OfferIndex from "../components/Offers"

const OfferTemplate = ({ data }) => {
  return (
    <>
      <Helmet htmlAttributes={{
    lang: 'pl',
    }}>
        <title>{`${data.markdownRemark.frontmatter.title} - Kancelaria Notarialna M.Dąbrowska & J.Szeszkowska - Warszawa Centrum`}</title>
        <meta name="description" content={data.markdownRemark.frontmatter.desc} />
      </Helmet>
      <Layout>
        <Banner />
        <OfferIndex data={data} />
      </Layout>
    </>

  );
};
export default OfferTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        desc
      }
    }
  }
`