import React from "react";
import { Link } from "gatsby"
import OfferNav from "./nav";
import { ContentBlock, Container, Grid, Item, TextBlock, Text } from "./styled"

const OfferIndex = ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    return (
        <ContentBlock>
            <Container>
                <Grid>
                    <Item>
                        <OfferNav activeItem={frontmatter.slug} />
                    </Item>
                    <Item>
                        <TextBlock>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </TextBlock>
                        <Text>
                            <a href="https://ekw.ms.gov.pl" target='_blank' rel='noopener noreferer'>  <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 10.625V0H1.875C0.835938 0 0 0.835938 0 1.875V38.125C0 39.1641 0.835938 40 1.875 40H28.125C29.1641 40 30 39.1641 30 38.125V12.5H19.375C18.3438 12.5 17.5 11.6562 17.5 10.625ZM23.4727 27.1375L15.9398 34.6141C15.4203 35.1305 14.5813 35.1305 14.0617 34.6141L6.52891 27.1375C5.73594 26.3508 6.29219 25 7.40781 25H12.5V18.75C12.5 18.0594 13.0594 17.5 13.75 17.5H16.25C16.9406 17.5 17.5 18.0594 17.5 18.75V25H22.5922C23.7078 25 24.2641 26.3508 23.4727 27.1375ZM29.4531 8.20312L21.8047 0.546875C21.4531 0.195312 20.9766 0 20.4766 0H20V10H30V9.52344C30 9.03125 29.8047 8.55469 29.4531 8.20312Z" fill="#113D69" />
                            </svg>
                                <p>Księgi wieczyste</p></a>
                        </Text>
                        <Text>
                            <a href="https://www.gov.pl/web/sprawiedliwosc/wyszukiwarka-krs1" target='_blank' rel='noopener noreferer'>   <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5 10.625V0H1.875C0.835938 0 0 0.835938 0 1.875V38.125C0 39.1641 0.835938 40 1.875 40H28.125C29.1641 40 30 39.1641 30 38.125V12.5H19.375C18.3438 12.5 17.5 11.6562 17.5 10.625ZM23.4727 27.1375L15.9398 34.6141C15.4203 35.1305 14.5813 35.1305 14.0617 34.6141L6.52891 27.1375C5.73594 26.3508 6.29219 25 7.40781 25H12.5V18.75C12.5 18.0594 13.0594 17.5 13.75 17.5H16.25C16.9406 17.5 17.5 18.0594 17.5 18.75V25H22.5922C23.7078 25 24.2641 26.3508 23.4727 27.1375ZM29.4531 8.20312L21.8047 0.546875C21.4531 0.195312 20.9766 0 20.4766 0H20V10H30V9.52344C30 9.03125 29.8047 8.55469 29.4531 8.20312Z" fill="#113D69" />
                            </svg>
                                <p>KRS</p></a>
                        </Text>
                    </Item>
                </Grid>
            </Container>
        </ContentBlock>
    );
};
export default OfferIndex;